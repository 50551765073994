import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import type { DocumentsRepository } from '@/modules/ctx-documents/adapter';
import type { Company, Document, DocumentFilter, Page } from '@/modules/ctx-documents/types';
import { DocumentState } from '@/modules/ctx-documents/types/DocumentState';
import type { AuthApi, CompaniesApi, DocumentRepresentation, DocumentsApi, UsersApi } from '@/modules/shared/adapter';
import { Role } from '@/modules/shared/types';
import { DocumentCategory } from '@/modules/ctx-documents/types/DocumentCategory';

export class DocumentsRepositoryImpl implements DocumentsRepository {

    private readonly documentsApi: DocumentsApi;
    private readonly companiesApi: CompaniesApi;
    private readonly usersApi: UsersApi;
    private readonly authApi: AuthApi;

    constructor(apis: {
        documents: DocumentsApi;
        companies: CompaniesApi;
        users: UsersApi;
        auth: AuthApi;
    }) {
        this.documentsApi = apis.documents;
        this.companiesApi = apis.companies;
        this.usersApi = apis.users;
        this.authApi = apis.auth;
    }

    public async getDocumentByKey(key: string): Promise<Document> {
        const representation = await this.documentsApi.getDocumentByKey(key);
        return this.mapDocumentToDomain(representation);
    }

    public async getDocuments(page: Page, filter?: DocumentFilter): Promise<Document[]> {
        const representations = await this.documentsApi.getDocuments({
            chunkSize: page.pageSize,
            chunkOffset: page.page,
            text: filter?.name,
            from: filter?.createdAfter?.getTime(),
            until: filter?.createdBefore?.getTime(),
            allTags: filter?.withTags,
            allGenerators: filter?.withGeneratorKeys,
            category: filter?.category,
        });
        return Promise.all(representations.map((it) => this.mapDocumentToDomain(it)));
    }

    public async deleteDocumentWarning(document: Document): Promise<void> {
        await this.documentsApi.deleteDocumentWarning(document.key);
    }

    public async deleteDocument(document: Document): Promise<void> {
        await this.documentsApi.deleteDocument(document.key);
    }

    public async getDocumentTypes(): Promise<string[]> {
        const types = await this.documentsApi.getDocumentTypes();
        return types.map((it) => it || 'other');
    }

    private async mapDocumentToDomain(representation: DocumentRepresentation): Promise<Document> {
        const signedInUser = await this.authApi.getUser();
        const role = await this.authApi.getUserRole();
        const createdBy = await this.usersApi.getUser(signedInUser.companyKey, representation.createdBy || '');
        return {
            key: representation.key || '',
            state: representation.state as DocumentState,
            name: representation.name || '',
            category: (representation.category || DocumentCategory.CATEGORY_OTHER) as DocumentCategory,
            createdBy: createdBy ? `${createdBy.firstname} ${createdBy.lastname}` : 'Halvar',
            dashboardKey: representation.dashboardKey,
            portfolioKey: representation.portfolioKey,
            createdAt: new Date(representation.createdAt || 0),
            generators: representation.generators.map((it) => ({
                key: it.key,
                name: it.name,
                parkKey: it.parkKey,
                parkName: it.parkName,
            })),
            generatorKeys: representation.generators.map((it) => it.key).filter(ArrayUtils.removeDuplicates),
            parks: representation.generators.map((it) => it.parkName).filter(ArrayUtils.removeDuplicates),
            uploadedAt: new Date(representation.uploadedAt || 0),
            isShared: representation.hasBeenShared,
            canRetry: this.getCanRetryForCategoryAndState(representation.category as DocumentCategory, representation.state as DocumentState),
            canDeleteWarning: (signedInUser.key === representation.createdBy || role === Role.HALVAR_ADMIN) && representation.state === DocumentState.Warning,
            canBeDeleted: signedInUser.key === representation.createdBy || role === Role.HALVAR_ADMIN,
        };
    }

    private getCanRetryForCategoryAndState(category: DocumentCategory, state: DocumentState): boolean {
        // always allow retry for halvar reports
        // only allow retry for reportheld reports if their state is warning or failed
        switch (category) {
            case DocumentCategory.CATEGORY_REPORT:
            case DocumentCategory.CATEGORY_REPORT_MONTHLY:
            case DocumentCategory.CATEGORY_REPORT_ANNUAL:
                return true;
            case DocumentCategory.CATEGORY_MONATSBEGEHUNG:
            case DocumentCategory.CATEGORY_HALBJAHRESBEGEHUNG:
            case DocumentCategory.CATEGORY_JAHRESBEGEHUNG:
                return state === DocumentState.Warning || state === DocumentState.Failed;
            default:
                return false;
        }
    }

    public async getSharedWithCompanies(docKey: string): Promise<Company[]> {
        const companyKeys = await this.documentsApi.getAssignedCompanyKeys(docKey);
        const companies = await this.companiesApi.getCompanies();
        return companyKeys
            .map((companyKey) => companies.find((it) => it.key === companyKey))
            .filter(ArrayUtils.filterUndefined)
            .map((it) => ({
                key: it.key,
                name: it.name,
                logo: it.logo,
                color: it.color,
            }));
    }

    public async shareDocumentWithCompany(docKey: string, companyKey: string): Promise<void> {
        await this.documentsApi.assignCompany(docKey, companyKey);
    }

    public async withdrawDocumentFromCompany(docKey: string, companyKey: string): Promise<void> {
        await this.documentsApi.removeCompany(docKey, companyKey);
    }

    public async downloadDocument(docKey: string): Promise<Blob> {
        return this.documentsApi.getDocumentAsPdf(docKey);
    }

    public async downloadDocumentsZipped(docKeys: string[]): Promise<Blob> {
        return this.documentsApi.getDocumentsAsZip(docKeys);
    }

    public async retryDocument(docKey: string): Promise<void> {
        return this.documentsApi.retryDocument(docKey);
    }
}
